import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import XyzYhrzCardUtil,{IXyzYhrzCardDataObj} from './xyzYhrzCardUtil';
export default defineComponent({
    name:'XyzYhrzCard',
    title:'信用证银行入账',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IXyzYhrzCardDataObj=reactive<IXyzYhrzCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/xyzYhrz')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                mainId: [utils.UtilPub.commonValidRule(proxy.$t('xyzYhrz.form.mainId_rule'))],
                date: [utils.UtilPub.commonValidRule(proxy.$t('xyzYhrz.form.date_rule'))],
                amount: [utils.UtilPub.commonValidRule(proxy.$t('xyzYhrz.form.amount_rule'))],
            },
            otherParams:{
                disableMainId:false,//控制信用证是否可编辑
                bzUnit:'元',
                xyzAmount:0,//信用证订单的金额之和（首付款、预付款、全款之和）
                xyzData:[],//信用证
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XyzYhrzCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'mainId'==params.comboId){
                    return dataObj.otherParams.xyzData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('mainId'==selectId){
                dataObj.form.sxf=0;
                dataObj.otherParams.xyzAmount=0;
                dataObj.otherParams.bzUnit='元';
                if(newValue){
                    let item=dataObj.otherParams.xyzData.find((item:any)=>item.value==newValue);
                    dataObj.otherParams.bzUnit=item.item.bz==0?'元':'$';
                    if(dataObj.form.amount>0){//输入了实际收汇金额才有计算手续费的必要
                        if(item){//找到所选信用证，计算信用证本来的订单金额
                            dataObj.otherParams.xyzAmount=item.item.amount;
                            //信用证本来的金额-实际收汇金额=手续费
                            dataObj.form.sxf=dataObj.otherParams.xyzAmount-dataObj.form.amount;
                        }
                    }
                }
            }
        }
        //监控实际收汇金额改变
        watch(() => dataObj.form.amount,async (newValue,oldValue) => {
            if(dataObj.form.mainId){//如果有选择信用证，才有计算手续费的必要
                let item=dataObj.otherParams.xyzData.find((item:any)=>item.value==dataObj.form.mainId);
                if(item){//找到所选信用证，计算信用证本来的订单金额
                    dataObj.otherParams.xyzAmount=item.item.amount;
                    dataObj.form.sxf=dataObj.otherParams.xyzAmount-newValue;
                }
            }
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if('/load'==addOrLoad && res.data.flag==1){
                dataObj.disabled=true;
            }
            if('/load'==addOrLoad){
                dataObj.otherParams.disableMainId=true;
            }
            if('/load'==addOrLoad){
                nextTick(()=>{
                    dataObj.otherParams.xyzData.forEach((item:any)=>{
                        if(item.value==res.data.mainId)dataObj.otherParams.bzUnit=(item.item.bz==0?'元':'$');
                    });
                })
            }
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        //格式化手续费金额
        const formatAmount=computed(()=>{
            return (params:any) => {
                return utils.UtilPub.toAmountFormat(params.amount,false,2)
            }
        })
        //提交
        const submitHandler=async ()=>{
            await dataObj.utilInst.doSubmit();
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,formatAmount,submitHandler
         
        }
    }
});
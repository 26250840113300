import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-32aff2c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "XyzYhrz" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_Unit = _resolveComponent("Unit")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "150px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 1
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "信用证",
              prop: "mainId"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('mainId',el),
                  modelValue: _ctx.form.mainId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.mainId) = $event)),
                  clearable: "",
                  placeholder: "请选择信用证",
                  style: {"width":"100%"},
                  disabled: _ctx.disabled || _ctx.otherParams.disableMainId,
                  onChange: _cache[1] || (_cache[1] = (val)=>{_ctx.selectOnChange(val,'mainId')})
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'mainId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value,
                        disabled: item.disabled
                      }, null, 8, ["label", "value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "承兑及收汇日期",
              prop: "date"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.form.date,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.date) = $event)),
                  placeholder: "请选择承兑及收汇日期",
                  disabled: _ctx.disabled
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "实际收汇金额",
              prop: "amount"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Unit, {
                  modelValue: _ctx.form.amount,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.amount) = $event)),
                  unit: _ctx.otherParams.bzUnit,
                  min: 0,
                  max: 9999999999,
                  disabled: _ctx.disabled
                }, null, 8, ["modelValue", "unit", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "手续费" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatAmount({amount:_ctx.form.sxf})), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-19defede"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "XyzJd" }
const _hoisted_2 = { style: {"height":"300px","overflow-y":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.otherParams.activeName,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.otherParams.activeName) = $event)),
      stretch: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "本次交单信息",
          name: "curJd"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              ref: "formRef",
              model: _ctx.form,
              rules: _ctx.rules,
              "label-width": "100px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CardForm, {
                  ownerInst: _ctx.proxy,
                  formItems: _ctx.compParams.formItems,
                  form: _ctx.form,
                  formRef: _ctx.formRef,
                  rules: _ctx.rules,
                  disabled: _ctx.disabled,
                  refMap: _ctx.refMap,
                  colNum: 1
                }, {
                  extendSlot: _withCtx(() => []),
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "信用证号",
                      prop: "mainId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('mainId',el),
                          modelValue: _ctx.form.mainId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.mainId) = $event)),
                          clearable: "",
                          placeholder: "请选择信用证号",
                          style: {"width":"100%"},
                          disabled: _ctx.disabled || _ctx.otherParams.disableMainId,
                          onChange: _cache[1] || (_cache[1] = (val)=>{_ctx.selectOnChange(val,'mainId')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'mainId'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: "订单",
                      prop: "orderId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('orderId',el),
                          modelValue: _ctx.form.orderId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.orderId) = $event)),
                          multiple: "",
                          clearable: "",
                          placeholder: "请选择订单",
                          style: {"width":"100%"},
                          disabled: _ctx.disabled || !_ctx.form.mainId,
                          onChange: _cache[3] || (_cache[3] = (val)=>{_ctx.selectOnChange(val,'orderId')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'orderId'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: "交单日期",
                      prop: "date"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.date,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.date) = $event)),
                          placeholder: "请选择交单日期",
                          disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: "交单金额",
                      prop: "amount"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[5] || (_cache[5] = e => _ctx.form.amount = _ctx.valid(e)),
                          modelValue: _ctx.form.amount,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.amount) = $event)),
                          placeholder: "请输入交单金额",
                          maxlength: "10",
                          disabled: true,
                          clearable: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "已完成交单信息",
          name: "finishJd",
          lazy: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.otherParams.finishJdList.length==0)
                ? (_openBlock(), _createBlock(_component_el_empty, {
                    key: 0,
                    description: "暂无信息"
                  }))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.otherParams.finishJdList, (item) => {
                    return (_openBlock(), _createBlock(_component_el_card, {
                      class: _normalizeClass(item.id==_ctx.form.id?'curJd':'')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_descriptions, {
                          direction: "vertical",
                          column: 3,
                          size: "small",
                          border: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_descriptions_item, { label: "信用证编码" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_tag, {
                                  size: "small",
                                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openXyzCard())),
                                  style: {"cursor":"pointer"},
                                  title: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.xyzCode), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_el_descriptions_item, { label: "交单日期" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.date), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_el_descriptions_item, { label: "交单金额" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.jdAmount({amount:item.amount})), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_el_descriptions_item, { label: "订单" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.orderCode), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["class"]))
                  }), 256))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
import {reactive, toRefs, computed,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import FinanceUtils from "@/views/project/order/finance/financeUtils";
import XyzCardUtil,{IXyzCardDataObj} from './xyzCardUtil';
import FormalOrderList from "@/views/project/order/order/formalOrder/FormalOrderList.vue";
import XyzJdCard from "@/views/project/order/finance/xyz/jd/XyzJdCard.vue";
import PayDetail from "@/views/project/order/finance/common/payDetail/PayDetail.vue"
export default defineComponent({
    name:'XyzCard',
    title:'信用证',
    modelType:'bill',
    fullscreen: true,
    components:{
        PayDetail
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IXyzCardDataObj=reactive<IXyzCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                approveLog:{
                    showAttachFile:false,
                    showApprovalMsg:true,
                    showSignInfo:false,
                },
                billModel:'xyz',
                hasDetails: true,
                detailInfos:{
                    detail:{},
                },
                details:['detail'],
                modelPath: utils.OrderProviderApi.buildUrl('/xyz')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                code: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.code_rule'))],
                bz: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.bz_rule'))],
                kzrq: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.kzrq_rule'))],
                yxq: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.yxq_rule'))],
                zczq: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.zczq_rule'))],
                zcjdr: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.zcjdr_rule'))],
                bank: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.bank_rule'))],
                customerId: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.customerId_rule'))],
            },
            otherParams:{
                cardFrom:'',//从哪个模块来
                bankData:[],//银行下拉数据
                customerData:[],//客户下拉数据
                activeName:'baseInfo',//激活的tab
                disableBfd:true,//是否可以输入信用证不符点
                bfd:'',//不符点
                childParams:{
                    cardFrom:'xyzCard',
                    gridTitle:'信用证订单明细',
                    urlPre:'xyz'
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XyzCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
                if(params && 'bank'==params.comboId){
                    return dataObj.otherParams.bankData;
                }
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })

        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.form.id,
                    fileSize:80,
                    // limitNum:1,
                    accept:'.txt,.jpg,.jpeg,.png,.pdf',
                    disabled:dataObj.disabled,
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })

        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('bz'==selectId){
                dataObj.form.amount=0.0;
                dataObj.form.customerId='';
                dataObj.refMap.get('detail').clearDetailData();
                dataObj.otherParams.customerData=await utils.OrderProviderApi.getAllCustomer({params:{from:'xyz',bz:newValue}});//查询所有客户
            }
            if('bank'==selectId){}
            if('customerId'==selectId){
                dataObj.refMap.get('detail').clearDetailData();
            }
        }
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        //选择订单按钮事件
        const chooseOrder=async ()=>{
            if(!dataObj.form.customerId){
                proxy.$message('请先选择客户');
                return;
            }
            await utils.UtilPub.openDialog({customerId:dataObj.form.customerId,bz:dataObj.form.bz,proxy:proxy,modelComp:FormalOrderList,ownerComp:proxy,
                from:'xyz',overflow:'hidden',title: '选择订单',contentHeight:'',showFooterBtn:true,fullscreen:true})
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            FinanceUtils.appendSelOrder(selectRows,dataObj.refMap.get('detail'));
            return true;
        }
        //日期选择改变事件
        const dateChange=(newValue:string,selectId:string)=>{
            if('zczq'==selectId){//最迟交单日=最迟装期+21
                dataObj.form.zcjdr=utils.UtilPub.formatDate(utils.UtilPub.getAfterDayDate(newValue,21));
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            //只有从信用证列表打开信用证卡片且是制单状态，信用证卡片才能修改
            if(res.curTask!='makeBill' || "XyzList"!=engineInst.engineParams.cardFrom){
                dataObj.disabled=true;
                dataObj.refMap.get('detail').setReadOnly(true);
            }else{
                dataObj.refMap.get('detail').setReadOnly(false);
            }
            //如果是制单状态，则可以填入信用证不符点
            dataObj.otherParams.disableBfd=(res.curTask!='makeBill');
            //不符点排序
            res.data.xyzBfdHistoryBeanList.sort((item1:any,item2:any)=>{
                return item1.time.localeCompare(item2.time);
            });
            //记录从哪里进入信用证卡片
            dataObj.otherParams.cardForm=engineInst.engineParams.cardFrom;
            //信用证交单卡片里面的交单历史、信用证交单列表、银行入账列表、收款确认列表，可以查看具体的信用证，也会传入cardFrom:xyzCard|SkqrList，这个时候直接选中订单信息tab，体验效果更好
            let cardFormArr=['xyzJdCard','xyzJdList','xyzYhrzList','SkqrList'];
            if(cardFormArr.includes(engineInst.engineParams.cardFrom))dataObj.otherParams.activeName='orderInfo';
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }

        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=async ()=>{
            //验证明细表格数据是否合法
            let detailData=dataObj.refMap.get('detail').getDetailData();
            let result:boolean=FinanceUtils.verifySaveData(detailData,proxy);
            return result;
        }


        //查看信用证交单弹出框
        const xyzJdDetail=async (xyzjdId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:xyzjdId,modelComp:XyzJdCard,ownerComp:proxy,cardFrom:'xyzCard',
                title:'交单详情',overflow:'hidden',showFooterBtn:false,fullscreen:false})
        }
        //上传之前
        const beforeSubmit=async (engineInst:any)=>{
            engineInst.submitData.exParams={bfd:dataObj.otherParams.bfd};
            return true;
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,setParam,beforeSaveHandler,buildUploadInitParams,handlePreview,
            chooseOrder,sureHandler,dateChange,xyzJdDetail, utils,beforeSubmit
        }
    }
});